import React from 'react'
import { Typography } from '@mui/material'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import { PageContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import { graphql } from 'gatsby'
import { makeStyles } from 'tss-react/mui'
import IntervalSlider from '@talentinc/gatsby-theme-ecom/components/IntervalSlider/IntervalSlider'
import ResumeReviewFAQ from '@talentinc/gatsby-theme-ecom/components/ResumeReview/ResumeReviewFAQ'
import ResumeReviewHero from '@talentinc/gatsby-theme-ecom/components/ResumeReview/ResumeReviewHero'
import { howResumeReviewWorksData, sliderItems } from '../../data/content'
import FooterCTA from '@talentinc/gatsby-theme-ecom/components/FooterCTA/FooterCTA'
import LandingPageV3Template, { Section } from '../../templates/landingPageV3'
import pageData from '../../data/resume-review.json'
import TestimonialsV2 from '@talentinc/gatsby-theme-ecom/components/TestimonialsV2/TestimonialsV2'
import ATS from '@talentinc/gatsby-theme-ecom/components/ATS/ATS'

type Props = {
  pageContext: PageContextType
}

export default function ResumeReview(props: Props) {
  const { classes } = useStyles()

  return (
    <LandingPageV3Template pageData={pageData} pageContext={props.pageContext}>
      <ResumeReviewHero />

      <Section>
        <Typography align="center" variant="h2" className={classes.title}>
          How our resume review works
        </Typography>
        <Typography align="center" variant="body1" className={classes.subtitle}>
          We'll provide personalized and actionable feedback in less than 48
          hours.
        </Typography>
        <div className={classes.itemsWrapper}>
          {howResumeReviewWorksData.map(({ title, subtitle, image }, index) => {
            return (
              <div key={index} className={classes.itemWrapper}>
                <div className={classes.itemTextWrapper}>
                  <Typography
                    align="center"
                    variant="h3"
                    className={classes.itemTitle}
                  >
                    {title}
                  </Typography>
                  <Typography
                    align="center"
                    variant="body1"
                    className={classes.itemSubtitle}
                  >
                    {subtitle}
                  </Typography>
                </div>

                <img src={image} alt={title} className={classes.itemImage} />
              </div>
            )
          })}
        </div>
      </Section>

      <Section innerPadded>
        <TestimonialsV2 />
      </Section>

      <Section>
        <div className={classes.sectionWhatToExpect}>
          <Typography align="center" variant="h2" className={classes.title}>
            What to expect from your review
          </Typography>
          <Typography
            align="center"
            variant="body1"
            className={classes.subtitle}
          >
            We review your resume comprehensively so you get all the information
            you need. Feedback topics include:
          </Typography>
          <IntervalSlider
            tabTimeOutInSecond={10}
            items={sliderItems}
            classes={{
              step: classes.sliderItemClass,
              activeStep: classes.sliderActiveItemClass,
            }}
          />
        </div>
      </Section>

      <Section innerPadded>
        <ATS />
      </Section>

      <Section innerPadded>
        <ResumeReviewFAQ />
      </Section>

      <FooterCTA />
      <Footer useSimulatedPageLoad={false} />
    </LandingPageV3Template>
  )
}

export const useStyles = makeStyles()((theme) => ({
  itemsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '32px',
    [theme.breakpoints.down(1024)]: {
      gap: '24px',
    },
    [theme.breakpoints.down('tablet')]: {
      gap: '16px',
      gridTemplateColumns: '1fr',
    },
  },
  itemWrapper: {
    backgroundColor: theme.colors.neutral[5],
    borderRadius: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('tablet')]: {
      marginBottom: theme.spacing(1),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down(1024)]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('tablet')]: {
      marginBottom: theme.spacing(3),
    },
  },
  itemTextWrapper: {
    padding: theme.spacing(4, 6),
    flex: 1,
    flexBasis: 0,
    [theme.breakpoints.down(1024)]: {
      padding: theme.spacing(3, 2),
    },
  },
  itemTitle: {
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: 23,
    lineHeight: '28px',
    color: theme.colors.neutral[90],
    marginBottom: 8,
  },
  itemSubtitle: {
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.colors.neutral[60],
  },
  itemImage: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('tablet')]: {
      maxWidth: 360,
    },
  },
  sectionWhatToExpect: {
    backgroundColor: theme.colors.neutral[5],
    paddingBlock: theme.spacing(6),
    paddingInline: 109,
    borderRadius: 40,
    // marginTop: theme.spacing(8),
    [theme.breakpoints.down('tablet')]: {
      paddingInline: 54,
    },
  },
  sliderItemClass: {
    background: theme.colors.neutral[10],
  },
  sliderActiveItemClass: {
    background: theme.colors.blue[250],
  },
}))

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
