import { Typography } from '@mui/material'
import FAQ from '@talentinc/gatsby-theme-ecom/components/FAQ'
import React from 'react'

const items = [
  {
    id: 1,
    question: 'How will I know if my resume is good or not?',
    answer:
      'We’ll give your clear feedback on what’s working and what’s not (yet). A resume review highlights your resume’s strengths and weaknesses. There are certain rules you need to follow. In the end, a good resume is measured by how many interviews you get!',
  },
  {
    id: 2,
    question: 'Is the resume review really free?',
    answer:
      'Yes, TopResume’s review is free. Our specialist team provides feedback on what’s working on your resume and where there’s room for improvement. This process usually takes around 48 hours and the resume review results are delivered to your inbox.',
  },
  {
    id: 3,
    question: 'Is a professional resume review worth it?',
    answer:
      "A free professional resume review from TopResume will identify areas for improvement, boost your resume's effectiveness, and increase your chances of landing a job interview. It's like a free investment for career success.",
  },
  {
    id: 4,
    question: 'Why should you trust TopResume to check your resume?',
    answer:
      'Our specialist team base their feedback on a combination of deep market insight, personal experience and advanced technology. We’ve helped hundreds of thousands of people to improve their resumes and land job interviews. When we check your resume, you’re getting direct feedback on how to impress recruiters.',
  },
  {
    id: 5,
    question: 'Will this give me an ATS score?',
    answer:
      "Yes, TopResume's review process includes an ATS (Applicant Tracking System) check. This ensures your resume is optimized for the ATS software used by employers. A high ATS score will significantly increase your chances of your resume getting through to hiring managers.",
  },
  {
    id: 6,
    question: 'How long will the review take?',
    answer:
      "Normally around 48 hours. Once you’ve uploaded your old resume, you'll receive an immediate confirmation email. In the meantime, we’ll run your resume analysis and prepare detailed feedback. When it’s ready, we’ll send you an email with a link to the breakdown.",
  },
]

function ResumeReviewFAQ() {
  return (
    <div>
      <Typography variant="h2">Frequently asked questions</Typography>
      {items.map(({ id, question, answer }) => (
        <FAQ
          key={`resume-review-faq-${id}`}
          contentful_id={id}
          question={question}
          answer={answer}
        />
      ))}
    </div>
  )
}

export default ResumeReviewFAQ
